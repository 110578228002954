import React from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // Carousel styling
import '../styles/About.css';

// image imports
import porsche from '../assets/porsche.jpg';
import about from '../assets/about.jpg';
import recon from '../assets/recon.jpg';
import service from '../assets/service.jpg';

const About = () => {
  return (
    <div className="about">
      <Carousel 
        autoPlay
        infiniteLoop
        showThumbs={false}
        showStatus={false}
        interval={3000}
        transitionTime={500}
      >
        <div>
          <img src={porsche} alt="Porsche" className="header-image"/>
        </div>
        <div>
          <img src={about} alt="About" className="header-image"/>
        </div>
        <div>
          <img src={recon} alt="Recon" className="header-image"/>
        </div>
        <div>
          <img src={service} alt="Service" className="header-image"/>
        </div>
      </Carousel>
      <h2>About Us</h2>
      <p>Revive Reconditioning, a premier car detailing service provider dedicated to delivering exceptional quality and service. With a keen understanding of the importance of presentation and 
      maintenance in the automotive industry, we are excited to extend our proposal to become your exclusive car detailing partner. At Revive Reconditioning we pride ourselves on offering a 
      comprehensive range of detailing services tailored to meet the unique needs of each vehicle. From exterior washing and waxing to interior deep cleaning and odor removal, our team of 
      skilled professionals is equipped to handle the full spectrum of detailing requirements. By entrusting your dealership's car detailing needs to us, you can rest assured that every vehicle on your 
      lot will be meticulously cared for and presented in its best possible condition. Our commitment to excellence extends beyond just the quality of our work; we also prioritize efficiency and 
      reliability to ensure minimal disruption to your operations. Furthermore, we understand the importance of cost-effectiveness in today's competitive market. That's why we are pleased to offer 
      highly competitive pricing for our services without compromising on quality. Our transparent pricing structure ensures that you receive exceptional value for your investment, allowing you to 
      maximize profitability while maintaining the highest standards of vehicle presentation. Partnering with Revive Reconditioning for your car detailing needs offers numerous benefits, 
      including: Consistent, high-quality results that enhance the visual appeal and resale value of your vehicles. Streamlined processes to minimize downtime and maximize efficiency. Flexible scheduling 
      options to accommodate your dealership's busy workflow. Competitive pricing to help you optimize your budget allocation without sacrificing quality. Dedicated customer support to address any 
      inquiries or concerns promptly. We are confident that our partnership will prove to be mutually beneficial, elevating the standard of vehicle presentation at your dealership while providing you 
      with unparalleled service and value. Thank you for considering Revive Reconditioning as your preferred car detailing provider. We look forward to the opportunity to discuss our proposal in more 
      detail and demonstrate how we can contribute to the success of your dealership.</p>

      <h2>Service Areas</h2>
      <ul>
        <li>California</li>
        <li>Arizona</li>
        <li>Texas</li>
      </ul>
    </div>
  );
};

export default About;
