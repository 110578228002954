import React from 'react';
import '../styles/Contact.css';

const Contact = () => {
  return (
    <div className="contact-container">
      <div className="contact">
        <h2>Contact Us</h2>
        <p>Email: reviverecon01@gmail.com</p>
        <p>Operation Director: Rafael Rosas</p>
        <p>Phone: (442) 215-6276</p>

        <p>Project Manager: Javier Rosas</p>
        <p>Phone: (760) 333-5420</p>

        <p>Field Manager: Esteban Rosas</p>
        <p>Phone: (760) 391-7977</p>
      </div>
    </div>
  );
};

export default Contact;
