import React from 'react';
import { Link } from 'react-router-dom';
import '../styles/Navbar.css';

// image import
import reviveLogo2 from '../assets/reviveLogo2.png'

const Navbar = () => {
  return (
    <nav className="navbar">
      <Link to="/"><img src={reviveLogo2} alt='revive-logo' className='logo'/></Link>
      <div className="links">
        <Link to="/">Home</Link>
        <Link to="/about">About</Link>
        <Link to="/services">Services</Link>
        <Link to="/contact">Contact</Link>
      </div>
    </nav>
  );
};

export default Navbar;
