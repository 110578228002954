import React from 'react';
import '../styles/Services.css';

// image imports
import newCar from '../assets/newCar.jpg';
import ReviveApp from '../assets/ReviveApp.png';
import service from '../assets/service.jpg';
import usedCar from '../assets/usedCar.jpg';
import repair from '../assets/repair.jpg';
import about from '../assets/about.jpg';

const Services = () => {
  return (
    <div className="services">
      <h2>Our Services</h2>
      <div className="service-section">
        <div className="service">
          <img src={newCar} alt="New Car Department" />
          <p>New Car Department</p>
        </div>
        <div className="service">
          <p>Service Department</p>
          <img src={service} alt="Service Department" />
        </div>
        <div className="service">
          <img src={usedCar} alt="Used Car Department" />
          <p>Used Car Department</p>
        </div>
        <div className="service">
          <p>Wheel Repair</p>
          <img src={repair} alt="Wheel Repair" />
        </div>
        <div className="service">
          <p>Reconditioning Services</p>
          <img src={about} alt="Reconditioning Services" />
        </div>
        <div className="service">
          <p>Reconpro App</p>
          <img src={ReviveApp} alt="Reconpro App" />
        </div>
      </div>
      <div className="other-services">
        <h3>Other Services</h3>
        <ul>
          <li>Paint touch up</li>
          <li>Ceramic Coating</li>
          <li>Spray on Bedliners</li>
          <li>Bumper Repair</li>
        </ul>
      </div>
    </div>
  );
};

export default Services;
