import React from 'react';
import '../styles/Home.css';
import Header from '../components/Header';
import { Link } from 'react-router-dom';

// image imports
import interior from '../assets/interior.png'
import newCar from '../assets/newCar.jpg'
import paintCorrection from '../assets/paintCorrection.jpg'
import wheelRepair from '../assets/wheelRepair.jpg'


const Home = () => {
  return (
    <div className="home">
      <Header />
      <section className="featured-services">
        <h3>Our Featured Services</h3>
        <div className="services-list">
          <div className="service-item">
            <img src={paintCorrection} alt="Paint Correction" />
            <p>Paint Correction</p>
          </div>
          <div className="service-item">
            <img src={interior} alt="Interior Detailing" />
            <p>Interior Detailing</p>
          </div>
          <div className="service-item">
            <img src={newCar} alt="Exterior Detailing" />
            <p>Exterior Detailing</p>
          </div>
          <div className="service-item">
            <img src={wheelRepair} alt="Exterior Detailing" />
            <p>Wheel Repair</p>
          </div>
        </div>
      </section>

      <section className="cta">
        <h3>Ready to Revive Your Car?</h3>
        <Link to="/contact"><button>Contact</button></Link>
      </section>
    </div>
  );
};

export default Home;
