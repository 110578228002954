import React from 'react';
import '../styles/Header.css';

// image imports
import revive from '../assets/reviveLogo2.png';

const Header = () => {
  return (
    <div className="header">
      <img src={revive} alt="Revive Reconditioning" />
      <div className="overlay">
        <h1>Revive Reconditioning</h1>
        <p>Where your car's dream comes true!</p>
        <p className="details">Licensed, Bonded, & Insured</p>
      </div>
    </div>
  );
};

export default Header;

